export const MARKETING_SITE_BASE_URL = 'https://www.abatable.com'

export const ROUTES = {
  INDEX: '/',
  CONTACT: '/contact',
  API: {
    AUTH: {
      LOGOUT: 'api/auth/logout',
    },
  },
  AUTH: {
    CALLBACK: '/auth/callback',
    VERIFY: '/auth/verify',
    SIGNUP: '/auth/signup',
    LOGOUT: '/auth/logout',
    LOGIN: '/auth/login',
  },
  USER: {
    ABOUT: '/user/about',
    THANKYOU_FOR_YOUR_INTEREST: '/user/thankyou-for-your-interest',
  },
  MARKETING_SITE: {
    CONTACT_US: `${MARKETING_SITE_BASE_URL}/contact-us`,
    BLOG: {
      INDEX: `${MARKETING_SITE_BASE_URL}/blog`,
      DATA_SOURCES: `${MARKETING_SITE_BASE_URL}/blog/data-sources`,
      VCM_INDEX_INSIGHTS: `${MARKETING_SITE_BASE_URL}/blog/vcm-investment-attractiveness-index-insights`,
      SUPPLY_FORECAST_EXPLAINED: `${MARKETING_SITE_BASE_URL}/blog/our-supply-forecast-explained`,
    },
    REPORTS: {
      DEVELOPER_ECOSYSTEM_REPORT: `${MARKETING_SITE_BASE_URL}/reports/voluntary-carbon-market-developer-overview-2023`,
    },
    MARKET_INTELLIGENCE: `${MARKETING_SITE_BASE_URL}/market-intelligence`,
    VCM_INDEX: `${MARKETING_SITE_BASE_URL}/vcm-investment-index`,
    PRIVACY_POLICY: `${MARKETING_SITE_BASE_URL}/privacy-policy`,
    TERMS: `${MARKETING_SITE_BASE_URL}/terms-and-conditions`,
    CARBON_GLOSSARY: `${MARKETING_SITE_BASE_URL}/carbon-glossary`,
  },
  VCM_ATTRACTIVENESS: {
    INDEX: '/vcm-attractiveness',
  },
  BUYER: {
    ONBOARDING: {
      RESULTS: '/buyer/onboarding/results',
      CONTACT: '/buyer/onboarding/contact',
      START: '/buyer/onboarding/start',
      VOLUME: '/buyer/onboarding/volume',
      CLAIM_INTRO: '/buyer/onboarding/intro',
      CLAIM_STRATEGY: '/buyer/onboarding/strategy',
      EXPLORE: '/buyer/onboarding/explore',
      CARBON_PROFILE: '/buyer/onboarding/carbon-profile',
      PROJECT_TYPES: '/buyer/onboarding/types',
      PROJECT_LOCATION: '/buyer/onboarding/location',
      PROJECT_REGISTRY: '/buyer/onboarding/registry',
      SUBMIT_DETAILS: '/buyer/onboarding/submit-details',
    },
  },
  MARKET_INTELLIGENCE: {
    INDEX: '/market-intelligence',
    POLICY_INSIGHTS: '/market-intelligence/policy-insights',
    PRICING: '/market-intelligence/pricing',
    SUPPLY_INSIGHTS: '/market-intelligence/supply-insights',
    CARBON_PROJECT_INTELLIGENCE:
      '/market-intelligence/carbon-project-intelligence',
    THEMES: '/market-intelligence/themes',
    EXPLAINERS: '/market-intelligence/explainers',
  },
  PROCUREMENT: {
    INDEX: '/procurement',
    DEVELOPER_DATABASE: '/procurement/developer-database',
    NEW: '/procurement/new',
    PROJECT_CRITERIA: '/procurement/project-criteria',
    PROPOSALS: '/procurement/proposals',
    RFP_INSTRUCTIONS: '/procurement/rfp-instructions',
    SEND_RFP: '/procurement/send-rfp',
    SHORTLIST: '/procurement/shortlist',
  },
  DEVELOPER: {
    INDEX: '/developer',
    INVITATIONS: '/developer/invitations',
    PROFILE: '/developer/profile',
    PROJECTS: '/developer/projects',
    SETUP_PROFILE: {
      INDEX: '/developer/setup-profile',
      ORGANISATION_DETAILS: '/developer/setup-profile/organisation-details',
      CONTACT_DETAILS: '/developer/setup-profile/contact-details',
      REVIEWING: '/developer/setup-profile/reviewing',
    },
  },
  NEW_LAYOUT: {
    INDEX: '/new-layout',
    MARKET_INTELLIGENCE: {
      INDEX: '/new-layout/market-intelligence',
      VCM_INDEX: '/new-layout/market-intelligence/vcm-investment-index',
      POLICY: '/new-layout/market-intelligence/policy',
      PRICING: '/new-layout/market-intelligence/pricing',
      SUPPLY: {
        INDEX: '/new-layout/market-intelligence/supply',
        PROJECT_DEVELOPER_ECOSYSTEM:
          '/new-layout/market-intelligence/supply/project-developer-ecosystem',
        FUNDING_INSIGHTS:
          '/new-layout/market-intelligence/supply/funding-insights',
        SUPPLY_FORECAST:
          '/new-layout/market-intelligence/supply/supply-forecast',
      },
      INTEGRITY_ANALYSIS: {
        INDEX: '/new-layout/market-intelligence/integrity-analysis',
      },
    },
  },
}
